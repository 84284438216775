body {
  background-color: #000000;
  color: #FFD700;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Add any additional custom styles here */